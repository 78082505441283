import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Quote from "../components/quote"
import Layout from "../components/layout"

// Construct the Social Links
const WorkLink = props => (
  <li>
    <a href={props.linkTo} className="social-button">
      {props.company}
      <span> &#8212; {props.projectTitle}</span>
      <span className={props.color}>{props.projectType}</span>
    </a>
  </li>
)
export default ({data}, props) => (  
  <Layout>
  <Helmet 
    bodyAttributes={{class:'work grey'}}
    title="JayJo Design | Jeffrey Jorgensen | My Work | Project & Case Studies"
  />   
    <div>
      <div className="section">
        <section className="work">
          <div className="wrapper narrow-med">
            <h1 className="section-header">Some of My Work</h1>
            <ul className="social-buttons">
              <WorkLink
                linkTo="/work/mixpanel/design-system/"
                color="project orange"
                projectType="Case Study"
                company="Mixpanel"
                projectTitle="Design System"
              />
              <WorkLink
                linkTo="/work/mixpanel/email-ios/"
                color="project orange"
                projectType="Case Study"
                company="Mixpanel"
                projectTitle="Email Digests"
              />
              <WorkLink
                linkTo="/work/mixpanel/insights/"
                color="project orange"
                projectType="Case Study"
                company="Mixpanel"
                projectTitle="Insights"
              />
              <WorkLink
                linkTo="http://keycodes.atjayjo.com"
                color="project green"
                projectType="Side Project"
                company="KeyCodes"
                projectTitle="An application to get CharCodes"
              />
              <WorkLink
                linkTo="https://arkiv.co"
                color="project green"
                projectType="Side Project"
                company="Arkiv"
                projectTitle="A curatin of product marketing sites"
              />
            </ul>
          </div>
        </section>
        <section className="quote">
          <div className="wrapper narrow-med">
            <Quote
              quoteText="Jeff's leadership is invaluable; he provides critical impact. Jeff is the glue that keeps this team together and we'd be in a much worse situation without him."
              subject="- Former Design Manager, 2018."
            />
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

// Get the Site Meta Data & JayJo Links
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`